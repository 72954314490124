<template>
  <div>
    <div v-if="recording">{{ progress }}%</div>
    <template v-else>
      <div>
        {{ time }}
        <img v-if="result == 1" src="/img/sun.png" />
        <img v-else-if="result == 2" src="/img/rain-cloud.png" />
        <img v-else-if="result == 3" src="/img/rain-cloud.png" />
      </div>

      <table>
        <tr v-for="voice in voices" :key="voice.id">
          <td>{{ format(voice.recorded_at, 'YYYY/MM/DD HH:mm') }}</td>
          <td>
            <img v-if="voice.result == 1" src="/img/sun.png" />
            <img v-else-if="voice.result == 2" src="/img/cloud.png" />
            <img v-else-if="voice.result == 3" src="/img/rain.png" />
          </td>
          <td>
            <span v-if="voice.result == 1">健常相当</span>
            <span v-else-if="voice.result == 2">やや問題あり</span>
            <span v-else-if="voice.result == 3">問題あり</span>
          </td>
        </tr>
      </table>
    </template>
  </div>
</template>

<style scoped>
img {
  width: 1.2rem;
  cursor: pointer;
}

p {
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Hiragino Kaku Gothic Pro", "Hiragino Sans", "Meiryo", "Segoe UI", "Arial", sans-serif;
}

table {
  margin-top: 50px;
}
</style>

<style>
.main {
  height: 100vh;
}

.sun {
  background: linear-gradient(to bottom, #bbeaff, #fff);
}

.rain-cloud {
  background: linear-gradient(to bottom, #ffc2c8, #fff);
}
</style>

<script>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import { useRoute } from "vue-router";
import { getSubject, getSubjectVoices, postSubjectVoice } from "@/common/api";
import { convertToWAV } from "@/common/wav";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export default {
  layout: "staff",
  setup() {
    const route = useRoute();
    const recording = ref(false);
    const result = ref(0);
    const error = ref(false);
    const progress = ref(0);
    const time = ref('');
    const voices = ref([]);

    watch(() => result.value, (r) => {
      if (r == 1) document.body.className = 'sun';
      else if (r == 2) document.body.className = 'rain-cloud';
      else if (r == 3) document.body.className = 'rain-cloud';
      else document.body.className = '';
    }, { immediate: true });

    onMounted(async () => {
      document.querySelector('.topbar').style.setProperty('display', 'none', 'important');

      // 最小サイズで画面右下に表示
      const width = 600;
      const height = 80;
      const top = screen.availWidth - (width + 10);
      const left = screen.availHeight - (height + 10);

      window.resizeTo(width, height);
      window.moveTo(top, left);

      if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
        let permission = await Notification.requestPermission();
        if (permission !== 'granted') {
          alert('通知の許可を得られませんでした。');
        }
      } else if (Notification.permission === 'denied') {
        alert('通知の利用が拒否されています。');
      }

      // 自動録音
      await record();
    });

    onBeforeUnmount(() => {
      document.body.className = '';
      document.querySelector('.topbar').removeProperty('display');
    });

    function notifyResult(result) {
      let icon;
      let body;
      if (result == 1) {
        body = '特に問題は認められませんでした。';
        icon = '/img/sun.png';
      } else if (result == 2 || result == 3) {
        body = '今日は少し脳がお疲れのようです。';
        icon = '/img/rain-cloud.png';
      }

      new Notification('解析結果', { body, icon, requireInteraction: true });
    }

    async function checkVoiceResult() {
      let data = await getSubject(route.params.id);
      if (data.subject?.voice) {
        if (data.subject.voice.result > 0) {
          result.value = data.subject.voice.result;
          time.value = dayjs().format("HH:mm:ss");
          if (Notification.permission === 'granted') {
            notifyResult(data.subject.voice.result);
          }
          let r = await getSubjectVoices(route.params.id);
          voices.value = r.subject_voices;
          recording.value = false;
          return;
        }
      }

      setTimeout(checkVoiceResult, 5000);
    }

    async function record() {
      progress.value = 0;
      recording.value = true;
      result.value = 0;

      let mediaRecorder;
      let recordedChunks = [];
      let start = 0;

      function checkProgress() {
        let ts = (new Date()).valueOf();

        if (ts - start > 30000) {
          mediaRecorder.stop();
          return;
        }

        progress.value = Number(((ts - start) / 32000.0) * 100).toFixed(1);
        setTimeout(checkProgress, 500);
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorder = new MediaRecorder(stream);

        mediaRecorder.ondataavailable = event => {
            if (event.data.size > 0) {
                recordedChunks.push(event.data);
            }
        };

        mediaRecorder.onstop = async () => {
          const audioContext = new AudioContext();
          const blob = convertToWAV(recordedChunks, audioContext.sampleRate);

          // サーバーにアップロード
          const formData = new FormData();
          formData.append('file', blob, 'sound.wav');
          formData.append("recorded_at", dayjs().utc().format("YYYY-MM-DD HH:mm"));

          try {
            await postSubjectVoice(formData, route.params.id);
          } catch (error) {
            console.error(error);
            alert('録音データの分析にエラーが発生しました');
            error.value = true;
            recording.value = false;
          }

          checkVoiceResult();
        };

        mediaRecorder.start();

        // 30秒後に録音を自動停止
        start = (new Date()).valueOf();
        setTimeout(checkProgress, 500);
      } catch (error) {
        console.error(error);
        alert('マイクへのアクセスが拒否されました、またはサポートされていません。');
        error.value = true;
      }
    }

    function format(val, fmt) {
      return dayjs(val).format(fmt);
    }

    return {
      recording, result, error, progress, time, voices,
      record, format,
    };
  }
}
</script>